:root {
    /* Font sizes */
    --text-very-small: 10px;
    --text-small: 11px;
    --text-small-medium: 12px;
    --text-medium-small: 13px;
    --text-medium-small-2: 14px;
    --text-medium: 15px;
    --text-medium-large: 16px;
    --text-large: 16px;
    --text-large-big: 20px;
    --text-big: 22px;

    /* Sidebar */

    /* --sidebar-width: 350px; */
    --sidebar-width: 200px;
    --sidebar-width-small: 185px;
    --sidebar-width-small-2: 56px;
    --sidebar-height-mobile: 56px;
    --sidebar-logo-max-w: 150px;

    /* Campaigns */

    --campaigns-gap: 2rem;
    --campaign-item-height: 45px;
    --animationTop: calc(var(--campaigns-gap) + var(--campaign-item-height));
    --campaigns-transition-duration: 300ms;

    /*** Autocomplete ***/

    /* Detached mode */
    --aa-detached-modal-max-width: 1000px;
    --aa-detached-modal-max-height: 625px;
    --aa-overlay-color-rgb: 229, 231, 235;
    --aa-overlay-color-alpha: 0.7;

    /* Input */
    --aa-search-input-height: 34px;
    --aa-font-size: 1rem;
    --aa-primary-color-rgb: 31, 41, 55;
}

.fc .fc-button {
    text-transform: capitalize !important;
}

#headlessui-dialog-2,
#headlessui-dialog-1 {
    z-index: 1 !important;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
    .dropdown-phone-left {
        width: 260px !important;
        left: -55px !important;
    }
    .dropdown-phone-overall {
        width: 260px !important;
        left: 0px !important;
    }
}

body {
    @apply text-color7;
}

.switch {
    position: relative;
    display: inline-block;
    width: 50px;
    height: 25px;
}

.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: var(--color-color6);
    -webkit-transition: 0.4s;
    transition: 0.4s;
}

.slider:before {
    position: absolute;
    content: "";
    height: 17px;
    width: 17px;
    left: 3px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: 0.4s;
    transition: 0.4s;
}

input:checked + .slider {
    @apply bg-color2;
}

input:focus + .slider {
    @apply shadow-color7 shadow-sm;
}

input:checked + .slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
    border-radius: 34px;
}

.slider.round:before {
    border-radius: 50%;
}

pre:not([class]) {
    display: none;
    width: 0;
}

.a-attachment-email {
    gap: 1rem;
    display: flex;
    flex-direction: row;
    --tw-border-opacity: 1;
    border-width: 1px;
    padding-left: 1.25rem;
    padding-right: 1.25rem;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    border-radius: 0.375rem;
    margin-bottom: 5px;
    @apply border-color6;
}

.a-attachment-email > img {
    width: 20px;
}

.a-attachment-email > div {
    display: flex;
    flex-direction: column;
}

.attachment-name {
    font-weight: 700;
    font-size: 1rem;
    line-height: 1.5rem;
}

.attachment-type {
    font-size: 0.75rem;
    line-height: 1rem;
    margin-top: -4px;
    text-transform: uppercase;
    @apply text-color6;
}

.react-datepicker-popper {
    min-width: 270px;
}

.react-datepicker {
    font-family: inherit !important;
    font-size: 0.6rem !important;
    border: transparent !important;
    border-radius: 0.3rem !important;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.react-datepicker__header {
    background-color: white !important;
    border-bottom: transparent !important;
}
.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--selected,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--selected,
.react-datepicker__year-text--in-selecting-range,
.react-datepicker__year-text--in-range {
    @apply !bg-color1;
}
.react-datepicker__day--keyboard-selected,
.react-datepicker__month-text--keyboard-selected,
.react-datepicker__quarter-text--keyboard-selected,
.react-datepicker__year-text--keyboard-selected {
    @apply !bg-color3;
}
.react-datepicker__day--keyboard-selected:hover,
.react-datepicker__month-text--keyboard-selected:hover,
.react-datepicker__quarter-text--keyboard-selected:hover,
.react-datepicker__year-text--keyboard-selected:hover {
    @apply !bg-color6;
}

.react-datepicker__current-month,
.react-datepicker-time__header,
.react-datepicker-year-header {
    @apply !text-color7;
}

.react-datepicker__month-select,
.react-datepicker__year-select {
    padding-top: 1px;
    padding-bottom: 1px;
    border-radius: 5px;
    margin-top: 5px;
    font-size: 14px !important;
}

@layer base {
    .attached-images > img {
        max-height: 200px;
        object-fit: contain;
    }

    .img-conversation > img,
    .img-conversation > div > img,
    .img-conversation img {
        width: auto !important;
        height: auto !important;
        max-height: 200px !important;
    }

    .w-fit-content {
        width: fit-content;
    }

    .bg-notes-lightbox {
        background: rgba(from var(--color-color3) r g b / 0.8);
    }

    .not-pinned path {
        @apply !stroke-color6;
        fill: none;
        stroke-width: 1px;
        stroke-dasharray: 0;
        stroke-linejoin: round;
    }

    .notes-list img {
        max-height: 300px;
    }

    .tooltip {
        position: relative;
        display: inline-block;
        cursor: default;
    }

    .tooltip .tooltiptext {
        visibility: hidden;
        width: 150px;
        background-color: var(--color-color3);
        color: var(--color-color7);
        text-align: center;
        border-radius: 6px;
        padding: 5px 0;
        position: absolute;
        z-index: 1;
        margin-left: -50px;
        opacity: 0;
        transition: opacity 0.3s;
        top: -5px;
        bottom: auto;
        right: 200%;
    }

    .tooltip .tooltiptext-right {
        visibility: hidden;
        width: 150px;
        background-color: var(--color-color3);
        color: var(--color-color7);
        text-align: center;
        border-radius: 6px;
        padding: 5px 0;
        position: absolute;
        z-index: 1;
        margin-right: -50px;
        opacity: 0;
        transition: opacity 0.3s;
        top: -15px;
        bottom: auto;
        left: 200%;
    }

    .tooltip .tooltiptext-right::after {
        content: "";
        position: absolute;
        top: 50%;
        right: 100%;
        margin-top: -5px;
        border-width: 5px;
        border-style: solid;
        border-color: transparent var(--color-color3) transparent transparent;
    }

    .tooltip .tooltiptext2 {
        width: 170px !important;
    }

    .tooltip .tooltiptext::after {
        content: "";
        position: absolute;
        top: 50%;
        left: 100%;
        margin-top: -5px;
        border-width: 5px;
        border-style: solid;
        border-color: transparent transparent transparent var(--color-color3);
    }

    .tooltip:hover .tooltiptext,
    .tooltip:hover .tooltiptext-right {
        visibility: visible;
        opacity: 1;
    }

    .arrow-top {
        width: 0;
        height: 0;
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
        @apply border-b-color4 border-b-[14px];
    }

    .step-item input::-webkit-outer-spin-button,
    .step-item input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    .h-fit {
        height: fit-content;
    }

    .container-phone .invalid-number-message {
        @apply text-xs bg-color11 pt-1 left-0 top-[51px] font-inter !important;
    }

    .phone-dropdown {
        @apply rounded-[7px] text-r-medium-small-2 font-inter !important;
    }

    .custom-phone {
        @apply focus:outline-none h-10 self-start outline-none w-full text-color7 border-color3 text-r-medium-small-2 focus:ring-color1
        focus:border-color1 transition-all rounded-[7px] py-1 pr-2 disabled:cursor-default disabled:bg-color15 !important;
    }

    .custom-phone-overall {
        @apply h-auto focus:outline-none self-start outline-none w-full text-color7 border-color3 text-r-medium-small-2 focus:ring-color1
        focus:border-color1 transition-all rounded-[7px] py-1 pr-2 disabled:cursor-default disabled:bg-color15 !important;
    }

    .overflow-x-clip {
        overflow-x: clip;
    }

    .step-item input[type="number"] {
        -moz-appearance: textfield;
    }

    .crm_container {
        @apply 3xl:max-w-[1600px] 3xl:mx-auto;
    }

    .error-text {
        @apply text-xs text-color11 pt-1;
    }

    .rounded7px {
        @apply rounded-[7px] !important;
    }

    input[type="date"],
    input[type="time"] {
        position: relative;
    }

    input:focus {
        outline: none !important;
        shadow: none !important;
        box-shadow: none !important;
    }

    .chip_input:focus {
        border: none !important;
        border-width: 0 !important;
        box-shadow: none !important;
        outline-width: 0 !important;
    }

    .calendar-table > table {
        position: relative;
    }

    .calendar-table > table > thead {
        position: sticky;
        top: 0px;
        /* z-index: 1; */
        background-color: white !important;
    }

    .crm-table th {
        @apply bg-color1 text-color7-contrast;
    }

    .crm-table th:first-child {
        @apply rounded-tl-md;
    }

    .crm-table th:last-child {
        @apply rounded-tr-md;
    }

    .mini-scrollbar.container::-webkit-scrollbar {
        height: 5px;
    }

    .mini-scrollbar.container::-webkit-scrollbar-thumb {
        background: var(--color-color3);
        border-radius: 4px;
    }

    input[type="date"]::-webkit-calendar-picker-indicator,
    input[type="time"]::-webkit-calendar-picker-indicator {
        background: url("./assets/svg/calendar_icon_solid.svg") no-repeat calc(100% - 10px) center;
        background-size: 17px;
        visibility: visible !important;
        cursor: pointer;
        position: absolute;
        top: 0;
        left: -25px;
        right: 0;
        bottom: 0;
        width: auto;
        height: auto;
    }

    input[type="date"].custom_date_input::-webkit-calendar-picker-indicator {
        background: url("./assets/svg/calendar_icon_solid_yellow.svg") no-repeat calc(100% - 10px)
            center;
        background-size: 18px;
    }

    input[type="date"].detailClientPageDate::-webkit-calendar-picker-indicator {
        background: transparent !important;
    }

    input[type="time"]::-webkit-calendar-picker-indicator {
        background: url("./assets/svg/time_icon.svg") no-repeat calc(100% - 10px) center;
        background-size: 22px;
    }

    input[data-address="true"] {
        background: url("./assets/svg/location_icon.svg") no-repeat;
        background-size: 25px;
        padding-right: 60px;
        background-position: calc(100% - 12px);
    }

    input[type="checkbox"] {
        @apply text-color1 focus:ring-color1 border-color1 rounded-sm;
    }

    .black-tick[type="checkbox"]:checked {
        background-image: url("./assets/svg/checkbox_tick.svg");
    }

    input[type="radio"] {
        @apply text-color1 focus:ring-color1 border-color1 !accent-color7;
    }

    .button-crm {
        @apply !bg-color1 hover:!bg-color6 text-color7-contrast self-center rounded-[7px] font-inter font-bold text-r-large outline-none focus:outline-none;
    }

    button:focus-visible {
        @apply ring ring-color2;
    }

    h1 {
        @apply text-color7 text-r-big leading-9 font-inter font-bold;
    }

    input,
    textarea {
        @apply focus:ring-color1 focus:border-color1;
    }

    @media (max-width: 576px) {
        .navbar-link-active {
            @apply bg-color7;
        }
    }

    .navbar-link-active > div > svg {
        @apply text-color2;
    }

    .sidebar-link:focus-visible {
        @apply text-color2;
    }

    .sidebar-link:hover svg {
        @apply text-color2;
    }

    .table-row:nth-child(even) {
        @apply bg-color8;
    }

    .table-row-disabled {
        @apply bg-color4 text-color6;
    }

    .hover-row {
        @apply !bg-color3 bg-opacity-100;
    }

    .form-row {
        @apply grid sm:grid-cols-2 xl:grid-cols-3 gap-y-3 gap-x-2;
    }

    table {
        @apply border-collapse m-0 p-0 w-full table-fixed;
    }

    .table-wrapper {
        @apply sm:rounded-[10px];
    }

    table th,
    table td {
        @apply text-left px-2;
    }

    .toolbar-class {
        @apply bg-color8  rounded-tl-[10px] rounded-tr-[10px] py-4 m-0 !important;
    }

    .justify-start-i {
        justify-content: start !important;
    }

    .toolbar-class .rdw-option-wrapper {
        @apply bg-color8 hover:ring-0 !important;
    }

    .toolbar-class .rdw-option-active {
        @apply ring-0 border border-color1 !important;
    }

    .editor-class {
        @apply border border-color8 rounded-bl-[10px] rounded-br-[10px] min-h-[200px] px-4;
    }

    .campaigns-wrapper::before {
        content: "";
        transform: translateX(-50%);
        @apply absolute w-[3px] bg-color3 top-0 bottom-0 left-1/2 z-[-1];
    }

    body .crm_select {
        @apply font-inter transition-all border-color3
        focus:ring-2
        focus:ring-color1
        focus:border-transparent
        focus-within:ring-2
        focus-within:border-transparent
        focus-within:ring-color1
        focus-within:ring-offset-0
        !important;
        @apply rounded-[5px];
    }

    body .crm_select_dark {
        @apply bg-color1 text-color8 relative !important;
    }

    .crm_select_dark::after {
        content: "";
        background: url("./assets/svg/users_icon_yellow.svg");
        @apply absolute w-[24px] h-[24px] right-[14px] top-1/2 transform translate-y-[-50%];
    }

    .crm_select_dark .react-dropdown-select-dropdown-handle {
        @apply hidden !important;
    }

    body .crm_select_dark-calendar {
        @apply bg-color1 text-color8 relative px-1 !important;
    }

    .crm_select_dark-calendar .react-dropdown-select-dropdown {
        @apply text-color7 !important;
    }

    body .crm_select .react-dropdown-select-item-selected {
        @apply bg-color1 hover:bg-color7;
    }

    body .crm_select .react-dropdown-select-item:not(.react-dropdown-select-item-selected) {
        @apply hover:bg-color3;
    }

    body .custom_select {
        @apply border-none font-inter font-medium text-r-large focus:ring-0 focus-within:ring-0;
    }

    body .custom_select_filter {
        @apply font-inter font-medium text-r-large focus:ring-0 focus-within:ring-0;
    }

    .custom_select .react-dropdown-select-item,
    .custom_select_filter .react-dropdown-select-item {
        @apply bg-color8 text-color7 border-none border-0 hover:!bg-color3 whitespace-nowrap font-normal focus:!shadow-none;
    }

    .custom_select .react-dropdown-select-dropdown,
    .custom_select_filter .react-dropdown-select-dropdown {
        @apply border-none w-max rounded-[5px];
    }

    .custom_select_multi .react-dropdown-select-dropdown {
        @apply border-none w-full rounded-[5px];
    }

    .custom_select_multi .react-dropdown-select-input::placeholder {
        @apply text-current font-inter font-medium;
    }

    .custom_select_filters .react-dropdown-select-dropdown {
        @apply border-none w-full rounded-[5px];
    }

    .custom_select_filters .react-dropdown-select-input::placeholder {
        @apply text-color7 text-r-medium-small-2;
    }

    .custom_select .react-dropdown-select-dropdown-handle svg,
    .custom_select_filter .react-dropdown-select-dropdown-handle svg {
        @apply w-[20px] h-[20px];
    }

    .custom_select .react-dropdown-select-dropdown-handle,
    .custom_select_filter .react-dropdown-select-dropdown-handle {
        @apply transition-all flex items-center justify-center;
    }

    .custom_select_multi {
        @apply text-r-medium bg-color8 min-h-[34px] !important;
        @apply rounded-[5px];
    }

    .custom_select_filters {
        @apply text-r-medium bg-color8 min-h-[34px] !important;
        @apply rounded-[5px];
    }

    body .custom_select_multi_2 {
        @apply focus:ring-color1 focus-within:border-none focus-within:ring-2 focus-within:ring-color1
        border-color3 focus:border-color1 py-0 min-h-[33px] !important;
    }

    .custom_select_multi .react-dropdown-select-option {
        @apply bg-color1 h-[30px] items-center rounded-[5px];
    }

    .custom_select_multi .react-dropdown-select-option .react-dropdown-select-option-label {
        @apply bg-color1 whitespace-nowrap;
    }

    .custom_select_multi .react-dropdown-select-option .react-dropdown-select-option-remove:hover {
        @apply text-color2;
    }

    .custom_select_filters .react-dropdown-select-option {
        @apply bg-color1 h-[30px] items-center rounded-[5px];
    }

    .custom_select_filters .react-dropdown-select-option .react-dropdown-select-option-label {
        @apply bg-color1 whitespace-nowrap;
    }

    .custom_select_filters
        .react-dropdown-select-option
        .react-dropdown-select-option-remove:hover {
        @apply text-color2;
    }

    .tab-link {
        @apply font-inter font-medium text-r-medium text-color6 pb-1 transition-colors whitespace-nowrap;
    }

    .tab-link-active {
        @apply border-b-[3px] border-color2 text-color7;
    }

    .custom-scroll::-webkit-scrollbar {
        @apply w-[10px];
    }

    .custom-scroll::-webkit-scrollbar-thumb {
        @apply bg-color1;
    }

    .custom-scroll::-webkit-scrollbar-track {
        @apply bg-color3;
    }

    .custom-scroll-blue::-webkit-scrollbar {
        @apply w-[10px];
    }

    .custom-scroll-blue::-webkit-scrollbar-thumb {
        @apply bg-color1;
    }

    .custom-scroll-blue::-webkit-scrollbar-track {
        @apply bg-color1/30;
    }

    .crm-calendar .fc table {
        @apply border-none;
    }

    .crm-calendar .fc table td,
    .crm-calendar .fc table td {
        @apply border-color3;
    }

    .crm-calendar .fc-toolbar-chunk > div {
        @apply flex items-center gap-2;
    }

    .crm-calendar .fc-toolbar-chunk > div > button {
        @apply bg-transparent text-color7 border-none;
    }

    .crm-calendar .fc-toolbar-chunk > div > .fc-toolbar-title {
        @apply font-inter font-bold text-color7 text-r-medium-large uppercase;
    }

    .crm-calendar .fc .fc-daygrid-day.fc-day-today {
        @apply bg-transparent;
    }

    .crm-calendar .fc-day-today .fc-daygrid-day-number {
        @apply bg-color1 text-color8 rounded-full w-[32px] h-[32px] text-center;
    }

    .checkbox_cell {
        @apply w-[86px] pl-8;
    }

    .feedBoxItem__iconWrapper::after {
        content: "";
        top: calc(50% + 1.35rem);
        @apply absolute bg-color6 z-[-1] h-full w-[1px];
    }

    .go_up {
        top: calc(var(--animationTop) * -1) !important;
    }

    .go_down {
        top: var(--animationTop) !important;
    }

    .custom_date_input {
        @apply bg-color1 text-color8 pl-4 !important;
    }

    .whitespace-normal-title {
        @apply whitespace-normal !important;
    }

    .min-w-200 {
        min-width: 200px;
    }

    @media (min-width: 1024px) and (max-width: 110px) {
        .gap-lg-xl {
            gap: 0.25rem !important;
        }
    }

    /* Spinner */

    .lds-ring {
        display: inline-block;
        position: relative;
        width: 80px;
        height: 80px;
    }
    .lds-ring div {
        box-sizing: border-box;
        display: block;
        position: absolute;
        width: 64px;
        height: 64px;
        margin: 8px;
        border: 8px solid var(--color-color6);
        border-radius: 50%;
        animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
        border-color: var(--color-color6) transparent transparent transparent;
    }
    .lds-ring div:nth-child(1) {
        animation-delay: -0.45s;
    }
    .lds-ring div:nth-child(2) {
        animation-delay: -0.3s;
    }
    .lds-ring div:nth-child(3) {
        animation-delay: -0.15s;
    }
    .loading-fade-in {
        opacity: 0;
        animation: fade-in 400ms forwards;
        animation-delay: 100ms;
    }

    /* Autocomplete */

    .aa-Grid {
        display: grid;
        column-gap: calc(var(--aa-spacing-half) / 2);
        height: 600px;
    }

    .aa-DetachedContainer .aa-Grid {
        grid-template-columns: 1fr;
    }

    .aa-DetachedContainer--modal .aa-Grid {
        grid-template-columns: 72% 1fr;
    }

    .aa-Column {
        padding: calc(var(--aa-spacing-half) / 2) 0;
    }

    .aa-Detached .aa-DetachedContainer {
        background: none;
    }

    .aa-DetachedContainer .aa-DetachedFormContainer {
        background: rgba(var(--aa-background-color-rgb), var(--aa-background-color-alpha));
        padding: calc(var(--aa-spacing-half) * 1.5);
    }

    .aa-DetachedContainer .aa-Preview {
        padding: 15px;
        display: none;
    }

    .aa-DetachedContainer--modal .aa-Preview {
        display: block;
    }

    .aa-PreviewTitle mark,
    .aa-PreviewDescription mark {
        background: none;
    }

    @keyframes fade-in {
        0% {
            opacity: 0;
        }
        100% {
            opacity: 1;
        }
    }

    @keyframes lds-ring {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);
        }
    }

    @media screen and (max-width: 640px) {
        .checkbox_cell {
            @apply w-auto mr-4;
        }

        .responsive-table thead {
            @apply hidden;
        }

        .responsive-table tr {
            @apply border-b-[30px] block border-color3 py-2;
        }

        .responsive-table tr.white-border {
            @apply border-b-[30px] block border-color8 py-2;
        }

        .responsive-table td {
            border-bottom: 1px solid lightgray;
            display: block;
            text-align: right;
            @apply text-right border-b-2 border-color3 py-4 text-xs;
        }

        .responsive-table td::before {
            content: attr(data-label);
            float: left;
            font-weight: bold;
            text-transform: uppercase;
        }

        .responsive-table td:last-child {
            border-bottom: 0;
        }

        .autocomplete-container {
            margin-left: -49px;
        }
    }
    .MuiOutlinedInput-notchedOutline:focus {
        @apply border-color1 !important;
    }
}

/* .MuiInputBase-input */
/* .MuiInputBase-inputAdornedEnd */
.css-nxo287-MuiInputBase-input-MuiOutlinedInput-input {
    /* padding: 5.5px 14px !important;
    border-radius: 8px !important; */
    border: 0px solid var(--color-color3) !important;
    outline-width: 0px !important;
    outline-color: transparent !important;
}
.css-1u3bzj6-MuiFormControl-root-MuiTextField-root {
    width: 100% !important;
}

.css-i44wyl {
    width: 100% !important;
}

.MuiOutlinedInput-root
    .MuiInputBase-root
    .MuiInputBase-colorPrimary
    .css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root {
    /* padding: 5.5px 14px !important;
    border-radius: 8px !important; */
    border: none !important;
    border: 0px solid var(--color-color3) !important;
    outline-width: 0px !important;
    outline-color: transparent !important;
}

.MuiOutlinedInput-notchedOutline {
    /* border-color: transparent */
    border: 1px solid var(--color-color3) !important;
    border-radius: 7px !important;
}

:focus-visible {
    outline: none;
}

body .react-dropdown-select-content {
    min-width: 0 !important;
}

.react-select input {
    box-shadow: none !important;
}

.fc-daygrid-dot-event {
    cursor: pointer;
    padding-top: 0 !important;
}

.fc-daygrid-event-harness {
    margin-left: 5px !important;
    margin-right: 5px !important;
    z-index: 0 !important;
}

.fc-direction-ltr .fc-daygrid-event.fc-event-end,
.fc-direction-rtl .fc-daygrid-event.fc-event-start {
    margin-left: 0 !important;
    margin-right: 0 !important;
}
.fc .fc-daygrid-day-top {
    flex-direction: row !important;
}

.fc-dayGridMonth-button,
.fc-timeGridWeek-button,
.fc-timeGridDay-button {
    @apply !bg-color1 !text-color8 !px-5 !py-2;
}
.fc-dayGridMonth-button.fc-button-active,
.fc-timeGridWeek-button.fc-button-active,
.fc-timeGridDay-button.fc-button-active {
    @apply !bg-color2 !text-color7;
}
.fc-button-group {
    gap: 0px !important;
}

.fc-button:focus {
    box-shadow: none !important;
}

.limit-1-line {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1; /* number of lines to show */
    line-clamp: 1;
    -webkit-box-orient: vertical;
}

.limit-2-lines {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2; /* number of lines to show */
    line-clamp: 2;
    -webkit-box-orient: vertical;
}

.limit-5-lines {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 5; /* number of lines to show */
    line-clamp: 5;
    -webkit-box-orient: vertical;
}

.fc-popover {
    visibility: hidden;
}

.fc-daygrid-more-link {
    position: inherit !important;
}

.fc-daygrid-day-events {
    position: initial !important;
}

@media screen and (min-width: 1280px) {
    .fc-daygrid-event-harness {
        margin-left: 5px !important;
        margin-right: 5px !important;
    }
}

@media screen and (max-width: 1024px) {
    .fc-daygrid-event-harness {
        margin-left: 6px !important;
        margin-right: 6px !important;
    }
}

@media screen and (max-width: 640px) {
    .fc-daygrid-event-harness {
        margin-left: 20px !important;
        margin-right: 20px !important;
    }
}

.callendar_event_appointment {
    background-color: var(--color-color14) !important;
    border-color: var(--color-color14) !important;
    color: var(--color-color8) !important;
    cursor: pointer;
}

.callendar_event_task {
    background-color: var(--color-color2) !important;
    border-color: var(--color-color2) !important;
    color: var(--color-color7) !important;
    cursor: pointer;
}

.callendar_event_task span {
    color: var(--color-color7) !important;
}

.callendar_event_task_completed span {
    color: var(--color-color7) !important;
}

.crm-calendar .fc-toolbar-chunk > div > .fc-toolbar-title {
    width: 170px;
    text-align: center;
}

/* Full Calendar */
@media (max-width: 600px) {
    .fc .fc-toolbar {
        flex-direction: column;
        gap: 10px;
    }
}

.fc-daygrid-event-harness {
    padding-bottom: 2px;
}

.fc-daygrid-day-top {
    padding: 2px 2px 5px;
}

.fc-header-toolbar .fc-toolbar-chunk {
    flex: 1;
}

.fc-header-toolbar div:nth-child(2) {
    flex: 1;
    display: flex;
    justify-content: center;
}

.fc .fc-daygrid-day-events {
    margin-top: 1em !important;
}

.fc-header-toolbar div:nth-child(3) {
    flex: 1;
    display: flex;
    justify-content: end;
}

.Tasks .callendar_event_appointment {
    display: none;
}

.Appointments .callendar_event_task {
    display: none;
}

.calendar_filter_holder select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    padding-right: 35px;
    background: url("./assets/svg/filter_icon_yellow.svg") no-repeat calc(100% - 10px) center;
    background-size: 14px;
}

.calendar_select_type.crm_select {
    min-height: 34px !important;
}

.calendar_select_type.crm_select:focus {
    box-shadow: none !important;
}
.ag-root.ag-layout-normal {
    overflow: show !important;
    padding-bottom: 20 !important;
}
.ag-header-cell .ag-header-row-column {
    background-color: "white" !important;
}
.ag-header {
    @apply !bg-color3;
}
.ag-theme-alpine .ag-root-wrapper {
    border: 0px !important;
    border-radius: 5px !important;
}
.ag-theme-alpine .ag-row {
    border-width: 0px !important;
    border-right-width: 0px !important;
    border-bottom-width: 1px !important;
    height: 30px !important;
}

.ag-row-hover, .ag-row-selected {
    background: var(--color-color4) !important;
}

.ag-theme-alpine .ag-header-cell,
.ag-theme-alpine .ag-header-group-cell {
    padding-left: 1px !important;
}

.ag-theme-alpine .ag-ltr .ag-header-select-all {
    margin-right: 12px !important;
}

.ag-root-wrapper-body.ag-layout-normal {
    height: auto !important;
}

.ag-cell-not-inline-editing {
    padding: 0px !important;
    padding-right: 15px !important;
    padding-left: 0px !important;
}
.ag-theme-alpine .ag-cell {
    line-height: 26px !important;
    @apply !border-r-color4 !border-r-[1px];
}

.ag-theme-alpine .ag-row {
    @apply !text-color7;
}

.ag-input-field-input .ag-text-field-input {
    padding-right: 0px !important;
    padding-top: 0px !important;
    padding-bottom: 0px !important;
    padding-left: 4px !important;
    font-size: 16px !important;
    padding-right: 0px !important;
}
.ag-theme-alpine .ag-header-cell-resize::after {
    height: 100% !important;
    top: 0% !important;
}

.customHeaderMenuButton {
    float: left;
    margin: 0 0 0 3px;
}

.detail-table-cell.ag-cell-focus.ag-cell-inline-editing {
    height: 30px;
}

.detail-table-cell-editor {
    padding-top: 3px;
    padding-bottom: 3px;
}

.customHeaderLabel {
    float: left;
    margin: 0 0 0 3px;
}

.customSortDownLabel {
    float: left;
    margin: 0 0 0 3px;
}

.customSortUpLabel {
    float: left;
    margin: 0;
}

.customSortRemoveLabel {
    float: left;
    margin: 0 0 0 3px;
    font-size: 11px;
}

.active {
    color: cornflowerblue;
}

.aa-Form {
    box-shadow: none;
    border: none;
}

.aa-Form[focus-within] {
    box-shadow: none;
    border: none;
}
.aa-Form:focus-within {
    box-shadow: none;
    border: none;
}

@media print {
    .PrintSection {
        padding: 40px !important;
    }
}

@media (max-width: 640px) {
    .aa-DetachedSearchButton {
        @apply !bg-color1;
    }

    .aa-DetachedSearchButton .aa-DetachedSearchButtonIcon {
        display: none;
    }
}

.aa-Autocomplete .aa-DetachedSearchButton {
    border-radius: 5px;
    border: none;
}

.aa-Input {
    border-radius: 5px;
}

.custom_checkbox {
    @apply !border-color3;
    border-radius: 8px !important;
}
.css-tlfecz-indicatorContainer {
    padding-left: 2px !important;
    padding-right: 2px !important;
}

.css-1gtu0rj-indicatorContainer {
    padding-left: 0px !important;
    padding-right: 2.5px !important;
}

.activity-contact-select {
    width: 270px;
}

.table-head-activity_description {
    width: 400px;
}

.form-radio-button {
    margin-top: 9px;
    @apply flex gap-4 items-center justify-start;
}
